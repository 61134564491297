import React, { useState, useEffect } from "react";
import FullScreenCarousel from "../components/Carousel";
import Services from "../components/Services";
import ServicesAnimation from "../components/ServicesAnimation";
import Portfolio from "../components/Portfolio";
import Specialities from "../components/Specialities";
import HomeScreenFooter from "../components/HomeScreenFooter";
import logo from "../images/voyage.png";
import Footer from "../components/Footer";

export default function HomeScreen({}) {
  return (
    <div style={{ color: "black" }}>
      <FullScreenCarousel />
      <Services />
      <ServicesAnimation />

      <section id="call-to-action" className="wow fadeIn ">
        <div className="container text-center">
          {/* <div className="text-4xl font-bold text-white ">10% off on your first booking</div>
          <a
            className="cta-btn"
            target="_blank"
            href="https://api.whatsapp.com/send?phone=33660089702"
            rel="noreferrer"
          >
            Book Now
          </a> */}
        </div>
      </section>

      <Portfolio />
      <Specialities />
      <HomeScreenFooter />

      {/* <div className="w-full bg-black p-3">
        <div className="flex flex-col md:flex-row items-center justify-between p-4 md:p-0 mx-auto max-w-screen-xl">
          <div className="text-white text-center mb-4 md:mb-0 md:w-1/3">
            <span className="box">
              Copyright 2019 Luxury Voyage Wellness, All Right Reserved
            </span>
          </div>
          <div className="text-center md:w-1/3">
            <img className="navbar-brand footer-logo w-20" src={logo} alt="Logo" />
          </div>
          <div className="flex justify-center md:justify-end items-center md:w-1/3">
            <a href="/" className="text-white mr-4">
              <p>Home</p>
            </a>
            <p className="text-white mr-4">Privacy Policy</p>
            <p className="text-white">Terms of Use</p>
          </div>
        </div>
      </div> */}

      {/* <Footer/> */}
    </div>
  );
}
